<template>
  <div class="app app-blank">
    <header
      class="app-header mt-2 flex flex-row items-center justify-start px-4"
    >
      <button @click="close()">
        <Icon name="material-symbols:arrow-back" />
        {{ t('Back to app') }}
      </button>
    </header>
    <main class="app-main">
      <div class="app-page container mx-auto my-8">
        <slot />
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const localizePath = useLocalePath()

type PreviousPathType = { path: string; query: Record<string, string> }
const previousPath = useState<PreviousPathType>('previous-path')

const close = () => {
  const prev = unref(previousPath)
  const path = localizePath(prev.path)

  navigateTo({
    path,
    query: prev.query,
  })
}
</script>
